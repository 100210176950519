import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import Logo from '../assets/SAFE FOCUS-1 png.png';

const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

const Navbar = () => {
  useScrollToTop();
  const [isOpen, setIsOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const logoAnimation = useSpring({
    from: { opacity: 0, transform: 'scale(0.8)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { tension: 300, friction: 20 },
  });

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 50);
      setIsScrolled(currentScrollPos > 50);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const navLinks = [
    { name: 'Home', path: '/' },
    { name: 'About Us', path: '/about' },
    { name: 'Services', path: '/services' },
    { name: 'Contact Us', path: '/contact' },
  ];

  const NavLink = ({ name, path }) => (
    <Link
      to={path}
      className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
        location.pathname === path
          ? 'text-blue-600'
          : 'text-gray-700 hover:text-blue-600'
      }`}
    >
      {name}
    </Link>
  );

  const PhoneIcon = () => (
    <a
      href="https://wa.me/+919633834648"
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-200"
      aria-label="WhatsApp Contact"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-6 h-6"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M20.52 3.48A12 12 0 003.48 20.52L2 22l1.48-4.48A12 12 0 1020.52 3.48zM12 20a8 8 0 01-4.39-1.31l-.31-.2-2.6.69.7-2.6-.2-.31A8 8 0 1112 20zm4.5-5.5c-.25-.13-1.47-.73-1.7-.82-.23-.09-.4-.13-.56.13-.16.25-.64.82-.79.98-.14.16-.29.18-.54.06s-1.06-.39-2.02-1.25a7.5 7.5 0 01-1.39-1.75c-.15-.25 0-.39.11-.51.11-.11.25-.29.37-.44.12-.15.16-.25.25-.41a.48.48 0 000-.46c-.09-.13-.56-1.35-.77-1.84-.2-.48-.41-.42-.56-.43h-.48a.92.92 0 00-.68.32 2.85 2.85 0 00-.88 2.11c0 1.24.9 2.44 1.03 2.61.13.16 1.77 2.7 4.3 3.79.6.26 1.07.42 1.43.54.6.19 1.15.16 1.58.1.48-.07 1.47-.6 1.68-1.18.21-.58.21-1.07.15-1.18-.05-.11-.23-.18-.48-.31z" />
      </svg>
    </a>
  );

  return (
    <>
      <nav
        className={`fixed top-0 left-0 w-full z-50 transition-transform duration-300 ${
          visible ? 'translate-y-0' : '-translate-y-full'
        } ${isScrolled ? 'bg-white shadow-md' : 'bg-transparent'}`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-20">
            <div className="flex items-center">
              <Link to="/" className="flex items-center">
                <animated.img
                  style={logoAnimation}
                  src={Logo}
                  alt="Company Logo"
                  className="h-20 w-auto sm:h-24 md:h-20"
                />
              </Link>
            </div>
            <div className="hidden md:flex space-x-6">
              {navLinks.map((link) => (
                <NavLink key={link.name} {...link} />
              ))}
            </div>
            <div className="hidden md:flex items-center space-x-4">
              <PhoneIcon />
            </div>
            <div className="md:hidden flex items-center space-x-4">
              <PhoneIcon />
              <button
                onClick={toggleNavbar}
                className="text-gray-700 hover:text-blue-600 focus:outline-none"
                aria-label="Toggle Menu"
              >
                {isOpen ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-8 h-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-8 h-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        {isOpen && (
          <div className="md:hidden bg-white shadow-md">
            <div className="px-4 pt-4 pb-6 space-y-4">
              {navLinks.map((link) => (
                <Link
                  key={link.name}
                  to={link.path}
                  onClick={toggleNavbar}
                  className={`block text-lg font-medium ${
                    location.pathname === link.path
                      ? 'text-blue-600'
                      : 'text-gray-700 hover:text-blue-600'
                  }`}
                >
                  {link.name}
                </Link>
              ))}
              <PhoneIcon />
            </div>
          </div>
        )}
      </nav>
      {/* Spacer */}
      <div className="h-20"></div>
    </>
  );
};

export default Navbar;
