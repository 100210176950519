import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Call from "../assets/icons/call.png";
import Email from "../assets/icons/email.png";
import Locat from "../assets/icons/location.png";
import ContactImg from "../assets/pikaso_reimagine_A-persons-hands-holding-a-white-sign-with-the-text.jpeg";
import Footer from './Footer';
import handleSubmit from './FormSubmitHandler';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Valid email is required';
    if (!formData.phone || !/^\d{10}$/.test(formData.phone)) newErrors.phone = 'Phone number must be 10 digits';
    if (!formData.subject) newErrors.subject = 'Subject is required';
    if (!formData.message) newErrors.message = 'Message is required';
    return newErrors;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      toast.error('Please fill in all required fields correctly.');
      return;
    }

    try {
      const response = await handleSubmit(event, formData, setErrors);
      console.log('Response from server:', response);

      if (response.status === 'email_sent') {
        // Clear the form data
        setFormData({
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: '',
        });
        setErrors({});

        // Show success toast message
        toast.success('Thanks for contacting us, we will be in touch with you soon.');
      } else if (response.status === 'error') {
        toast.error(`An error occurred: ${response.message}`);
      } else {
        toast.error('Unexpected response received. Please try again later.');
      }
    } catch (error) {
      console.error('Submission error:', error);
      toast.error('An error occurred while submitting the form. Please try again.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Background Image */}
      <div className="relative mb-6 sm:mb-10">
        <img src={ContactImg} alt="Contact Background" className="w-full h-auto object-cover" />
      </div>

      {/* Main Content */}
      <div className="flex-grow flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-6xl">
          <div className="flex flex-col lg:flex-row bg-white rounded-lg shadow-lg overflow-hidden">
            {/* Left Column (Contact Info) */}
            <div className="lg:w-1/2 p-6 sm:p-8 bg-gray-50 flex flex-col justify-around">
              <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-6">Stay Tuned & Lets Get In Touch With Us</h1>
              <div className="flex flex-col space-y-4">
                <div className="flex items-start sm:items-center">
                  <img src={Locat} alt="Location icon" className="w-8 h-8 sm:w-12 sm:h-12 bg-blue-500 rounded-full p-2 mr-4 flex-shrink-0" />
                  <span className="text-sm sm:text-base"> SAFE FOCUS security system  Shappumukku junction, Pavumba,Karunagapally,Kollam, Kerala 690518</span>
                </div>
                <div className="flex items-center">
                  <img src={Email} alt="Email icon" className="w-8 h-8 sm:w-12 sm:h-12 bg-blue-500 rounded-full p-2 mr-4 flex-shrink-0" />
                  <span className="text-sm sm:text-base">safefocuspavumba@gmail.com</span>
                </div>
                <div className="flex items-center">
                  <img src={Call} alt="Phone icon" className="w-8 h-8 sm:w-12 sm:h-12 bg-blue-500 rounded-full p-2 mr-4 flex-shrink-0" />
                  <span className="text-sm sm:text-base">+91 9633834648</span>
                </div>
              </div>
            </div>

            {/* Right Column (Contact Form) */}
            <div className="lg:w-1/2 p-6 sm:p-8 bg-blue-100 rounded-[10%]">
              <h2 className="text-xl sm:text-2xl font-bold mb-6">Send us a message</h2>
              <form onSubmit={handleFormSubmit}>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">Your name</label>
                    <input
                      className={`shadow rounded-3xl h-12 sm:h-16 appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.name ? 'border-red-500' : ''}`}
                      id="name"
                      type="text"
                      placeholder="Your name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Your email</label>
                    <input
                      className={`shadow rounded-3xl h-12 sm:h-16 appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.email ? 'border-red-500' : ''}`}
                      id="email"
                      type="email"
                      placeholder="Your email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">Your phone number</label>
                    <input
                      className={`shadow rounded-3xl h-12 sm:h-16 appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.phone ? 'border-red-500' : ''}`}
                      id="phone"
                      type="tel"
                      placeholder="Your phone number"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    {errors.phone && <p className="text-red-500 text-xs mt-1">{errors.phone}</p>}
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="subject">Subject</label>
                    <input
                      className={`shadow rounded-3xl h-12 sm:h-16 appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.subject ? 'border-red-500' : ''}`}
                      id="subject"
                      type="text"
                      placeholder="Subject"
                      value={formData.subject}
                      onChange={handleChange}
                    />
                    {errors.subject && <p className="text-red-500 text-xs mt-1">{errors.subject}</p>}
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">Message</label>
                  <textarea
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.message ? 'border-red-500' : ''}`}
                    id="message"
                    rows="5"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  {errors.message && <p className="text-red-500 text-xs mt-1">{errors.message}</p>}
                </div>
                <button
                  className="mt-4 rounded-[40px] h-12 bg-yellow-600 hover:bg-yellow-700 text-white font-bold py-2 px-4 w-full focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>

          {/* Google Maps Embed */}
          <div className="mt-8">
            <iframe
              key="google-map"
              title="SAFE FOCUS security system Location Map"
              className="w-full h-96 md:h-screen"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.5120788893705!2d76.52535121477574!3d9.104296393471631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0602a6b60b1f57%3A0x3c8c4c463bb8f4c!2sSafe%20Focus!5e0!3m2!1sen!2sin!4v1629450928000!5m2!1sen!2sin"
              style={{ border: '0' }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>

      <Footer />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Contact;
