import React, { useState, useEffect } from 'react';
import { FaLightbulb, FaBullseye } from 'react-icons/fa';
import about1 from '../assets/smart-home-technology-modern-technologically-advanced-living-space (1).jpg';
import about2 from '../assets/about2.jpg';
import about3 from '../assets/about3.jpg';
import about4 from '../assets/smart-home-interface-with-augmented-realty-iot-object-interior-design (1).jpg';
import about from '../assets/wom (1).jpg';
import about5 from '../assets/about5.jpg';
import about6 from '../assets/living-room-with-tv-tv-with-words-internet-wall.jpg';
import persn1 from '../assets/person1.jpg';
import persn2 from '../assets/person2.jpg';
import persn3 from '../assets/person3.jpg';
import persn4 from '../assets/cheerful-clerk-worker-wearing-suit-tie-looking-camera-office (1).jpg';
import Footer from './Footer';
import { useTransition, animated } from 'react-spring';

const testimonials = [
  {
    text: "The team at SAFE FOCUS Security System transformed our home into a smart paradise. From automated lighting to a state-of-the-art security system, everything works seamlessly. It's not just convenient; it's changed how we live.",
    name: "Jane Anderson",
    image: persn2,
  },
  {
    text: "The team at SAFE FOCUS Security System transformed our home into a smart paradise. From automated lighting to a state-of-the-art security system, everything works seamlessly. It's not just convenient; it's changed how we live.",
    name: "Jane Anderson",
    image: persn4,
  },
  {
    text: "I wasn't sure about smart home technology at first, but now I can't imagine living without it. The convenience of controlling our home's climate, lighting, and security from our phones is unparalleled. It's like living in the future.",
    name: "Michael.o.p",
    image: persn1,
  },
  {
    text: "Our home theatre system has completely revolutionized our movie nights. The high definition projector and surround sound make us feel like we're in a real cinema. It's an immersive experience that we look forward to every week.",
    name: "C.H John",
    image: persn3,
  },
];

const TestimonialCarousel = ({ startIndex = 0 }) => {
  const [index, setIndex] = useState(startIndex);

  const transitions = useTransition(index, {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((state) => (state + 1) % testimonials.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full h-full">
    {transitions((style, i) => (
      <animated.div
        key={i}
        style={style}
        className="absolute inset-0 bg-white shadow-lg rounded-lg overflow-hidden flex flex-col justify-between p-4 sm:p-6"
      >
        <p className="font-poppins text-container text-gray-600 mb-6 max-w-2xl mx-auto px-4 text-justify leading-relaxed">
          {testimonials[i].text}
        </p>
        <div className="flex items-center">
          <img src={testimonials[i].image} alt={testimonials[i].name} className="w-12 h-12 rounded-full mr-4" />
          <span className="font-bold text-sm sm:text-base">{testimonials[i].name}</span>
        </div>
      </animated.div>
    ))}
  </div>
  );
};

const About = () => {
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="relative h-[500px] bg-gray-800 mb-12">
        <img src={about} alt="Hero" className="w-full h-full object-cover opacity-70" />

      </div>
      
      {/* Main Content */}
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Image Grid */}
          <div className="md:w-1/2 grid grid-cols-3 grid-rows-3 gap-2 h-[400px]">
            <img src={about1} alt="Person using smartphone" className="col-span-2 row-span-2 w-full h-full object-cover rounded-lg" />
            <img src={about2} alt="Smart home control panel" className="w-full h-full object-cover rounded-lg" />
            <img src={about4} alt="Cozy living room" className="w-full h-full object-cover rounded-lg" />
            <img src={about3} alt="Technician with solar panels" className="col-span-2 w-full h-full object-cover rounded-lg" />
          </div>
          
          {/* Text Content */}
          <div className="md:w-1/2 flex flex-col justify-center">
            <h3 className="text-teal-500 text-lg mb-2 px-4 sm:px-6 md:px-8 lg:px-10">About Us</h3>
            <h2 className="text-3xl sm:text-4xl font-bold mb-4 px-4 sm:px-6 md:px-8 lg:px-10">Achieve Efficiency And Comfort Through Home Automation</h2>
            <div className="right-aligned-container">
           
            <p className="font-poppins text-container text-gray-600 mb-6 max-w-2xl mx-auto px-4 text-justify leading-relaxed">
  Experience comfort and innovation with SAFE FOCUS Secure System Home Automation solutions. Impeccably
  integrate technology for convenience and control in every aspect of your home. Harmonize lighting,
  climate, security, and entertainment for the perfect ambiance. Our expertise ensures an intelligent system
  adapting to your needs, enhancing efficiency and luxury.
</p>

<p className="font-poppins text-container text-gray-600 mb-6 max-w-2xl mx-auto px-4 text-justify leading-relaxed">
  With Secure Focus, enjoy advanced security, remote monitoring, and contribute to energy conservation for
  a smarter, sustainable home. Explore smart living with our comprehensive automation options.
</p>
</div>
          </div>
        </div>
      </div>

      {/* Vision and Mission */}
      <div className="bg-gray-100 py-12 mt-12">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row gap-8">
          <div className="md:w-1/3 bg-white p-6 rounded-lg shadow">
  <FaLightbulb className="text-4xl text-blue-500 mb-4" />
  <h3 className="text-xl font-bold mb-2 px-2 sm:px-3 md:px-4 lg:px-5">Our Vision</h3>
  
              <p className="font-poppins text-container text-gray-600 mb-6 max-w-2xl mx-auto px-4 text-justify leading-relaxed">
              SAFE FOCUS Security System aims to use technology to enhance daily life through seamless automation. 
    We strive to create the human experience and promote a connected, sustainable world.
  </p>
</div>
<div className="md:w-1/3 bg-white p-6 rounded-lg shadow">
  <FaBullseye className="text-4xl text-blue-500 mb-4" />
  <h3 className="text-xl font-bold mb-2 px-2 sm:px-3 md:px-4 lg:px-5">Our Mission</h3>
  
  <p className="font-poppins text-container text-gray-600 mb-6 max-w-2xl mx-auto px-4 text-justify leading-relaxed">
    Our comprehensive services ranging from home automation, home theatre systems, and entrance automation, 
    security systems & access control, and solar energy solutions are designed to enhance comfort, security, 
    and sustainability.
  </p>
</div>
            <div className="md:w-1/3 bg-white p-6 rounded-lg shadow">
              <h3 className="text-xl font-bold mb-2">Let's Transform your Home Together</h3>
              <img src={about5} alt="Transform" className="w-full h-40 object-cover rounded" />
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div className="py-12 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-8">Testimonials</h2>
          <h3 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8">Transform Your Home into Modern Masterpiece</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="h-64 md:h-auto">
              <img src={about6} alt="Worker installing solar panel" className="w-full h-full object-cover rounded-lg" />
            </div>
            <div className="h-64 md:h-auto bg-white shadow-lg rounded-lg overflow-hidden">
              <TestimonialCarousel startIndex={0} />
            </div>
            <div className="h-64 md:h-auto bg-white shadow-lg rounded-lg overflow-hidden">
              <TestimonialCarousel startIndex={1} />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default About;